export var SearchList = [
  { labe: '公司代码', code: 'companyCode', type: 'input', placeholder: '编码/名称' },
  { labe: '发票号码', code: 'invoiceNo', type: 'input', width: '200px' },
  { labe: '备注', code: 'bz', type: 'input' },
  {
    labe: '匹配状态',
    code: 'isMatching',
    type: 'select',
    option: [
      { label: '已匹配', value: '0' },
      { label: '未匹配', value: '1' }
    ]
  },
  
  {
    labe: '发票状态',
    code: 'invoiceStatus',
    type: 'select',
    option: [
      { label: '正常', value: '0' },
      { label: '失控', value: '1' },
      { label: '作废', value: '2' },
      { label: '冲红', value: '3' },
      { label: '异常', value: '4' },
      { label: '红字发票待确认', value: '80' },
      { label: '已部分冲红', value: '7' },
      { label: '已全额冲红', value: '8' },
    ]
  },
  {
    labe: '签收状态',
    code: 'isEleSignFor',
    type: 'select',
    option: [
      { label: '已签收', value: '0' },
      { label: '未签收', value: '1' }
    ]
  },
  { labe: '网上发票号码', code: 'onlineInvoiceNumber', type: 'input', width: '200px' },
  {
    labe: '开票日期',
    code: 'invoiceDate',
    type: 'daterange'
  },
  {
    labe: '采集日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '标识',
    code: 'isManual',
    type: 'select',
    option: [
      { label: '手工录入', value: 0 },
      { label: '系统获取', value: 1 },
      { label: '批量导入', value: 2 },
      { label: 'XML导入', value: 3 }
    ]
  },
  {
    labe: 'XML匹配状态',
    code: 'xmlMatchingStatus',
    type: 'select',
    option: [
      { label: '已匹配', value: 'Y' },
      { label: '未匹配', value: 'N' }
    ]
  },
  {
    labe: 'XML匹配日期',
    code: 'xmlMatchingDate',
    type: 'daterange'
  }
]
export var SearchData = {
  companyCode: '',
  invoiceNo: '',
  bz: '',
  isMatching: '',
  invoiceStatus: '',
  isEleSignFor: '',
  onlineInvoiceNumber: '',
  invoiceDateStartTime: '',
  invoiceDateEndTime: '',
  createDateStartTime: '',
  createDateEndTime: ''
}

export var tableField = [
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  { label: '公司代码', code: 'company', type: 'input', width: '' },
  { label: '发票号码', code: 'invoiceNo', type: 'input', width: '200px' },
  { label: '发票代码', code: 'invoiceCode', type: 'input', width: '' },
  {
    label: '发票状态',
    code: 'invoiceStatus',
    type: 'function',
    width: '',
    handle (index, row) {
      return row.invoiceStatus === '0' ? '正常' : row.invoiceStatus === '1' ? '失控' : row.invoiceStatus === '2' ? '作废' : row.invoiceStatus === '3' ? '冲红' : row.invoiceStatus === '4' ? '异常' :  row.invoiceStatus === '80' ? '红字发票待确认' : row.invoiceStatus === '7' ? '已部分冲红' :  row.invoiceStatus === '8' ? '已全额冲红' :  row.invoiceStatus
    }
  },
  { label: '开票日期', code: 'invoiceDate', type: 'input', width: '' },
  { label: '含税金额', code: 'totalAmount', type: 'input', width: '' },
  { label: '不含税金额', code: 'invoiceAmount', type: 'input', width: '' },
  { label: '税额', code: 'taxAmount', type: 'input', width: '' },
  {
    label: '标识',
    code: 'isManual',
    type: 'function',
    width: '',
    handle (index, row) {
      return row.isManual === 0 ? '手工录入' : row.isManual === 1 ? '系统获取' : row.isManual === 2 ? '批量导入' : row.isManual === 3 ? 'XML导入' : row.isManual
    }
  },
  {
    label: '匹配状态',
    code: 'isMatching',
    type: 'function',
    width: '',
    handle (index, row) {
      return row.isMatching === 0 ? '是' : row.isMatching === 1 ? '否' : row.isMatching
    }
  },
  {
    label: '签收状态',
    code: 'isEleSignFor',
    type: 'function',
    width: '',
    handle (index, row) {
      return row.isEleSignFor === 1 ? '未签收' : row.isEleSignFor === 0 ? '已签收' : row.isEleSignFor === 2 ? '取消签收' : row.isEleSignFor
    }
  },
  { label: '网上发票号码', code: 'onlineInvoiceNumber', type: 'input', width: '200px' },
  { label: '销方税号', code: 'salerTaxNo', type: 'input', width: '' },
  { label: '销方名称', code: 'salerName', type: 'input', width: '' },
  { label: '购方税号', code: 'buyerTaxNo', type: 'input', width: '' },
  { label: '备注', code: 'remark', type: 'input', width: '' },
  { label: 'XML匹配状态', code: 'xmlMatchingStatus', type: 'function', width: '',handle (index, row) {
    return row.xmlMatchingStatus === 'Y' ? '已匹配' : row.xmlMatchingStatus === 'N' ? '未匹配' : row.xmlMatchingStatus
  } },
  { label: 'XML匹配日期', code: 'xmlMatchingDate', type: 'input', width: '' },
  { label: 'XML文件地址', code: 'filePath', type: 'input', width: '' },
]

export var tableData = []
