<template>
<!-- 采购 网上发票列表 -->
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
         <el-button @click="invoiceInfoExportList" class="sw-downmb" type="text" v-if="isShowButton">税务发票导出</el-button>
        <el-button @click="uploadToServer" class="sw-upload-zip" type="text" v-if="isShowButton">上传XML压缩包</el-button>
        <el-button @click="invoiceTotalDown" class="sw-down-zip" type="text" v-if="isShowButton">下载XML压缩包</el-button>
        <el-button @click="downmb" class="downmb" type="text" >发票导入模板</el-button>
        <el-button @click="handlePreview " class="derivebtn" type="text" >发票导入</el-button>
      </div>
      <global-table ref="globalTableRef" @selectionChange = "rowSelect" :tableField="tableField" :tableData="tableData" @tableAction="tableAction" :paginationData="paginationData" @pageChange="pageChange" ></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request, requesFile, baseURL, exportForm, exportFormBody } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData } from './js/invoiceStatement'
import qs from 'qs'
export default {
  components: Component.components,
  name: 'InvoiceStatement',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      menudata: this.$store.state.ViewMenuData.menuData,
      paginationData: {},
      pageNum: 1,
      selectData: [],
      pageSize: pageSize,
      childData: { searchList: SearchList, searchData: SearchData },
      isShowButton: false
    }
  },
  mounted () {
    this.load()

    if (localStorage.getItem('roleCode') && (localStorage.getItem('roleCode').includes('应付会计【审批】') || localStorage.getItem('roleCode').includes('系统管理员'))) {
      this.isShowButton = true
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    // 勾选下载包
    invoiceTotalDown () {
    //   const formData = []
    //   if(!this.selectData.length){
    //     this.$message({
    //             message: '请至少选一条下载！',
    //             type: 'error'
    //           })
    //     return
    //   }

      //   this.selectData.map(s=>{
      //     formData.push(s.id)
      //   })
      let data = {}
      data.pageNum = 1
      data.pageSize = 10
      data = { ...data, ...this.searchdata }

      try {
        exportFormBody(`/api/reconciliation/invoiceInfo/listXmlExport?${qs.stringify(data)}`, 'get', data).then((res) => {
          const blob = new Blob([res.data], { type: 'application/zip' })
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            const fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], 'UTF-8')
            elink.target = 'hrefTemplate'
            elink.download = fileName
            elink.href = URL.createObjectURL(blob)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
          } else { // IE10+下载
            // eslint-disable-next-line no-undef
            navigator.msSaveBlob(blob, fileName)
          }
        })
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 上传包
    uploadToServer () {
      try {
        const input = document.createElement('input')
        input.type = 'file'
        input.click()
        input.onchange = () => {
          const formData = new FormData()
          formData.append('file', input.files[0])
          requesFile(baseURL + '/api/reconciliation/invoiceTotal/uploadToServer', 'post', formData).then((response) => {
            if (response.code === '200') {
              this.$message({
                message: response.data,
                type: 'success'
              })
            }
          })
        }
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 税务导出
    invoiceInfoExportList () {
      // 必输项
      // eslint-disable-next-line eqeqeq
      if (this.searchdata.isMatching == '') {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '匹配状态必输'
        })
        return false
      }
      // eslint-disable-next-line eqeqeq
      if (this.searchdata.isEleSignFor == '') {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '签收状态必输'
        })
        return false
      }

      let data = {}
      data.pageNum = 1
      data.pageSize = 10
      data = { ...data, ...this.searchdata }
      exportForm(`/api/reconciliation/invoiceInfo/exportList?${qs.stringify(data)}`, 'get', data).then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        eleLink.click()
        window.URL.revokeObjectURL(url)
      })
    },
    downmb () {
      window.open(baseURL + '/api/file/m/file/downloadFile/773063514583326720')
    },
    handlePreview () {
      try {
        const input = document.createElement('input')
        input.type = 'file'
        input.click()
        input.onchange = () => {
          const formData = new FormData()
          formData.append('file', input.files[0])
          requesFile(baseURL + '/api/reconciliation/invoiceInfo/upload/invoiceInfo', 'post', formData).then((response) => {
            if (response.code === '200') {
              this.pageNum = 1
              const obj = {
                pageSize: this.pageSize,
                pageNum: this.pageNum
              }
              request('/api/reconciliation/invoiceInfo/list', 'get', obj).then((res) => {
                if (res.code === '200') {
                  this.tableData = res.data.records
                  this.paginationData = { pageSize: this.pageSize, total: res.data.total }
                }
              })
              this.$message({
                message: '导入成功',
                type: 'success'
              })
            }
          })
        }
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    buttonClick () {},
    tableAction () {},
    rowSelect (data) {
      this.selectData = data
    },
    load () {
      var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      request('/api/reconciliation/invoiceInfo/list', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      delete this.searchdata.invoiceDate
      delete this.searchdata.xmlMatchingDate

      if (data.createDate) {
        this.searchdata.createDateStartTime = this.dateFormate(data.createDate[0])
        this.searchdata.createDateEndTime = this.dateFormate(data.createDate[1])
      }
      if (data.invoiceDate) {
        this.searchdata.invoiceDateStartTime = this.dateFormate(data.invoiceDate[0])
        this.searchdata.invoiceDateEndTime = this.dateFormate(data.invoiceDate[1])
      }

      if (data.xmlMatchingDate) {
        this.searchdata.xmlStartTime = this.dateFormate(data.xmlMatchingDate[0])
        this.searchdata.xmlEndTime = this.dateFormate(data.xmlMatchingDate[1])
      }
      request('/api/reconciliation/invoiceInfo/list', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/reconciliation/invoiceInfo/list', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.contractMenu{
 position: relative;
 height: 55px;
 .derivebtn{
  position: absolute;
  right: 35px;
  top: 8px;
  font-size: 16px;
 }
 .downmb{
  position: absolute;
  right: 150px;
  top: 8px;
  font-size: 16px;
 }
 .sw-down-zip{
    position: absolute;
  right: 290px;
  top: 8px;
  font-size: 16px;
 }
  .sw-upload-zip{
    position: absolute;
  right: 460px;
  top: 8px;
  font-size: 16px;
 }
  .sw-downmb{
    position: absolute;
  right: 626px;
  top: 8px;
  font-size: 16px;
 }

}
</style>
